import axios, { AxiosError } from "axios";

import { useAuth } from "./auth/useAuth";

let API_URL = process.env.REACT_APP_API_URL;

export const useAxios = () => {
  const { getToken, singIn } = useAuth();

  const api = axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  api.interceptors.request.use(async (config) => {
    const token = await getToken();
    if (token){
      if (token && config && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    }else{
      //to evict infinite loop on login screen and TV screen
      if (!config.url?.includes("login") && !config.url?.includes("tv")) {
        window.location.href = "/";
      }
      return config;
    }
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      const token = getToken();
      if (err instanceof AxiosError) {
        console.log(err.status);
      }
      return new Promise(async (resolve, reject) => {
        const originalReq = err.config;
        // Inverter o if
        // console.log(err.response.status);
        if (
          err.response?.status === 401 &&
          err.config &&
          !err.config?._retry &&
          !err.config?.url?.endsWith("login")
        ) {
          originalReq._retry = true;

          let res = axios
            .put(`${API_URL}token/refresh`, { oldToken: token })
            .then((res) => {
              console.log(res.data);
              if (res.data.access_token) {
                singIn({
                  token: res.data.access_token,
                  role: res.data.roles?.map((role: any) => role.name)?.join(","),
                });
              }
              originalReq.headers[
                "Authorization"
              ] = `Bearer ${res.data.access_token}`;
              return axios(originalReq);
            })
            .catch((err) => console.log(`Deu erro: ${err}`));
          resolve(res);
        } else {
          reject(err);
        }
      });
    }
  );

  return {
    get: api.get,
    post: api.post,
    put: api.put,
    del: api.delete,
    patch: api.patch,
    options: api.options,
  };
};
